@import '../../../utils/variables';

.container {
    width: 96rem;
    margin: 0 auto 15rem;
}

.content {
    width: 60rem;
    margin: 0 auto;
}
.img {
    width: 96rem;
    height: 50rem;
    margin: 0 0 7.25rem;
}
.imgInside {
    width: 100%;
    height: 100%;
}
.contentTitle {
    margin: 0 0 1.65rem;
    color: $key;
    font-size: 3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.155rem;
    text-align: center;
}
.contentInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 3.65rem;
}
.contentInfoInside {
    padding: 0 1.75rem;
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.155rem;
}
.contentText {
    margin: 0 0 4rem;
    color: $key;
    font-size: 1.3rem;
    letter-spacing: 0.0625rem;
    line-height: 2;
}
.contentLink {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 8rem;
    padding: 1.35rem;
    background-color: $key_sub;
    border-radius: 4rem;
}
.contentLinkText {
    color: $white;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.155rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        width: 100%;
        margin: 0 auto 10rem;
    }
    .content {
        width: 100%;
    }
    .img {
        width: 37.5rem;
        height: 37.5rem;
        margin: 0 0 4.05rem;
    }
    .content {
        width: 30rem;
    }
    .contentTitle {
        line-height: 1.325;
    }
    .contentInfo {
        margin: 0 0 1.65rem;
    }
    .contentInfoInside {
        padding: 0 0.85rem;
        font-size: 1.3rem;
        letter-spacing: 0.135rem;
    }
    .contentText {
        margin: 0 0 3rem;
        line-height: 1.7;
    }
    .contentLink {
        min-height: 6rem;
    }
    .contentLinkText {
        font-size: 1.3rem;
        font-family: $gilroySemi;
    }
}
