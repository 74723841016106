@import '../../../utils/variables';

.container {
    margin: 5rem 0 10rem;
    overflow: hidden;
}
.svg {
    width: 100%;
    margin: 0 0 -2.25rem;
}
.content {
    width: 96rem;
    margin: 0 auto;
}
.contentTitle {
    width: 50%;
    margin: 0px 0 4.25rem;
    color: $key;
    font-size: 3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.155rem;
}
.contentText {
    width: 50%;
    margin: 0 0 2.6rem 0.4rem;
    color: $key;
    font-size: 1.3rem;
    letter-spacing: 0.065rem;
    line-height: 2;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        margin: 3rem 0 2.75rem;
    }
    .svg {
        width: 41.4rem;
        height: 14.1rem;
        margin: 0 0 3.7rem -3rem;
    }
    .content {
        width: 30rem;
    }
    .contentTitle {
        width: 20rem;
        margin: 0px 0 3rem;
        line-height: 1.335;
    }
    .contentText {
        width: 100%;
        margin: 0 0 2.2rem;
        line-height: 1.7;
    }
}
